






































import { isEmpty } from 'lodash';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

export type ConditionOption = {
  label: string;
  value: string;
};

export type Expression = {
  conditionName: string;
  conditionSymbol: string;
  conditionValue: string;
  conditionOperator: string;
  conditionLabel: string;
};

@Component
export default class Search extends Vue {
  @Prop() conditionNames!: ConditionOption[];
  @Prop() conditionSymbols!: ConditionOption[];
  @Prop() expressions!: Expression[];
  @Prop() maxLen!: number;

  get defaultName(): string {
    return isEmpty(this.conditionNames) ? '' : this.conditionNames[0].value;
  }

  get defaultLabel(): string {
    return isEmpty(this.conditionNames) ? '' : this.conditionNames[0].label;
  }

  get defaultSymbol(): string {
    return isEmpty(this.conditionSymbols) ? '' : this.conditionSymbols[0].value;
  }

  get initExpression(): Expression {
    return {
      conditionName: this.defaultName,
      conditionSymbol: this.defaultSymbol,
      conditionValue: '',
      conditionOperator: '',
      conditionLabel: this.defaultLabel,
    };
  }

  exp: Expression[] = [];

  @Watch('conditionNames', { immediate: true })
  watchConditionNames(): void {
    this.exp = isEmpty(this.expressions)
      ? [this.initExpression]
      : this.expressions;
  }

  @Watch('exp', { deep: true })
  watchExp(val: Expression[]): void {
    this.$emit(
      'change',
      val.map((v) => {
        const item = this.conditionNames.find(
          (item) => item.value === v.conditionName
        );
        return {
          ...v,
          conditionLabel: item?.label,
        };
      })
    );
  }

  handleChange(i: number, value: string): void {
    const next = i + 1;
    if (!value && next !== this.exp.length) {
      this.exp[i].conditionOperator = this.exp[next].conditionOperator;
      this.exp.splice(i + 1, 1);
    } else if (value && i + 1 === this.exp.length) {
      this.exp.push({
        conditionName: this.defaultName,
        conditionSymbol: this.defaultSymbol,
        conditionValue: '',
        conditionOperator: '',
        conditionLabel: this.defaultLabel,
      });
    }
  }
}
